import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import counterUp from 'counterup2'
import '../sass/index.sass';
require('waypoints/lib/noframework.waypoints.js')
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { 
    faAngleRight,
    faAngleLeft,
    faCircleInfo,
    // 以下共用
    faEnvelope,
    faGlobe,
    faArrowUp,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { 
    // 以下共用
    faFacebookF,
    faYoutube,
    faXTwitter,
    faInstagram,
    faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'

library.add(faAngleRight, faAngleLeft,faCircleInfo,faEnvelope, faGlobe, faArrowUp, faChevronRight, faFacebookF, faYoutube, faXTwitter, faInstagram, faLinkedinIn)
dom.watch()


$(function() {


        var swiper = new Swiper('.index-slider', {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 1000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,
            autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
            },
            navigation: {
                nextEl: '.index-slider .swiper-button-next',
                prevEl: '.index-slider .swiper-button-prev'
            },
            modules: [Navigation, EffectFade, Autoplay],
    
        });

        var swiperTop = new Swiper('.index-topPicks .swiper-container', {
            slidesPerView: 'auto',
            spaceBetween: 15,
            loop: true,
            // loopAdditionalSlides: 1,
            centeredSlides: true,
            // simulateTouch: false,
            grabCursor: true,
            // autoplay: {
            //     delay: 1500,
            //     stopOnLastSlide: false,
            //     disableOnInteraction: false,
            // },
            pagination: {
                el: '.index-topPicks .swiper-pagination',
                clickable: true,
            },
            // navigation: {
            //     nextEl: '.index-topPicks .swiper-button-next',
            //     prevEl: '.index-topPicks .swiper-button-prev'
            // },
            breakpoints: {
                575: {
                    loop: false,
                    centeredSlides: false,
                    spaceBetween: 30,
                },
            },
            modules: [Pagination, Autoplay],
    
        });

        swiperTop.slideToLoop(0);
 


    //數字動化
    const el = document.querySelector('.counter1')
    new Waypoint({
        element: el,
        handler: function() {
            counterUp(el)
            this.destroy()
        },
        offset: 'bottom-in-view',
    })
    const el2 = document.querySelector('.counter2')
    new Waypoint({
        element: el2,
        handler: function() {
            counterUp(el2)
            this.destroy()
        },
        offset: 'bottom-in-view',
    })
    const el3 = document.querySelector('.counter3')
    new Waypoint({
        element: el3,
        handler: function() {
            counterUp(el3)
            this.destroy()
        },
        offset: 'bottom-in-view',
    })
    const el4 = document.querySelector('.counter4')
    new Waypoint({
        element: el4,
        handler: function() {
            counterUp(el4)
            this.destroy()
        },
        offset: 'bottom-in-view',
    })


    ///彩池金額
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) == 0) { return c.substring(name.length, c.length); }
        }
        return "";
    }


    var bonus
    var bonusArray
    var maxBonus = 274508775
    var minBonus = 117608323

    //把金額放進 html 顯示
    function bonusUpdata(val) {

        bonus = parseInt(val)
        bonusArray = Array.from(String(val))
        $.each(bonusArray, function(i, item) {
            $('.bonus-value b').eq(i).attr("data-val", item)
        });

    }
    if (getCookie("jackpot")) {
        bonusUpdata(getCookie("jackpot"))
    } else {
        bonusUpdata(minBonus)
    }

    function getRandom(min, max) {
        return Math.floor(Math.random() * max) + min;
    };


    let randomTrigerTime 
    setTimeout(function run(){

        bonus += getRandom(2000, 10000)
            //console.log(bonus)
        if (bonus < maxBonus) {
            bonusUpdata(bonus)
        } else {
            bonus = minBonus
                //console.log(bonus)
            bonusUpdata(bonus)
        }
        setCookie("jackpot", bonus, 30)

        randomTrigerTime = getRandom(500, 3000)
        setTimeout(run, randomTrigerTime)

    },1000)



})